.image-upload {
    // position: absolute;
    // top: 0;
    // left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    padding-top: 110px;
    // background-color: wheat; 
    width: 100vw;
    min-height: 100vh;
    // height: 2000px;
    // z-index: 100000;

    // button {
    //     padding: 10px 20px;
    //     font-size: 16px;
    //     cursor: pointer;
    //     border: none;
    //     border-radius: 5px;
    //     background-color: #007bff;
    //     color: white;
    //     transition: background-color 0.3s ease;

    //     &:hover {
    //         background-color: #0056b3;
    //     }
    // }

    input[type="file"] {
        display: none;
    }

    .image-preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        img {
            max-width: 100%;
            max-height: 300px;
        }
    }

    .loading {
        margin-top: 6px;
        font-size: 18px;
        font-weight: bold;
        color: var(text-color-dark);
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .processed-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        &:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }

        img {
            max-width: 100%;
            max-height: 300px;
        }
    }

    .button-line {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-top: 20px;
    }
}
