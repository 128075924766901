@use 'StyleGuide.scss'as *;

.ShowSection {
    padding-top: 90px;
    padding-bottom: 90px;
    width: 100vw;
    max-width: 1920;
}
.Design{

}
.Program{
   @include flex-centerAligned;
   gap: 30px;
}