@use 'StyleGuide.scss'as *;

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    height: fit-content;

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        padding: $space-lg $space-lg;
        background-color: var(--background);
        @include layered-box(0.01);
        transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
        border-bottom: 6px solid var(--background-color-dark);
        color: var(--text-color-dark);

        .left,
        .center,
        .right {
            width: 1/3;
            display: flex;
            align-items: center;
        }

        .left {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .logo {
                height: 36px;
                margin-right: 10px;
                border-radius: 14px;
            }

            h5 {
                letter-spacing: 0.5px;
            }
        }

        .center {
            justify-content: center;
        }

        .right {
            justify-content: flex-end;
        }

        .center,
        .right {
            .list-box {
                list-style: none;
                // margin-left: $space-lg;

                li {
                    display: inline;
                    margin-right: $space-xl;

                    a {
                        font-family: $secondary-font;
                        color: var(--text-color-dark);
                        text-align: center;
                        padding: 4px 2px;
                        text-decoration: none;
                        font-size: $large_font;
                        // font-weight: bold;
                        @include hover-line(2px, $start-color: var(--primary-color), $end-color: var(--primary-color));
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .selected a {
                    background: linear-gradient(to right, var(--primary-color), var(--primary-color)) no-repeat right bottom;
                    background-size: 100% 2px;
                }

                @media (max-width: 768px) {
                    display: none;
                }
            }
        }

        .hamburger {
            display: none;

            .ham-icon {
                font-size: 18px;
                position: relative;
                height: 100%;
                margin-left: $space-lg;
                z-index: 10000;
            }

            @media (max-width: 768px) {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }

        .popupMenu {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: $space-xxl;
            color: var(--text-color-light);

            li {
                padding-bottom: $space-md;
                list-style-type: decimal-leading-zero;
                margin-left: 8vw;

                a {
                    cursor: pointer;
                    float: left;
                    font-size: $xlarge_font;
                    display: block;
                    margin: 0 $space-xs;
                    padding: $space-sm $space-sm;
                    text-align: center;
                    text-decoration: none;
                    @include hover-line($start-color: var(--text-color-light), $end-color: var(--text-color-light));
                }
            }

            .selected a {
                background: linear-gradient(to right, var(--text-color-light), var(--text-color-light)) no-repeat right bottom;
                background-size: 100% 2px;
            }
        }

        &.hidden {
            transform: translateY(-100px);
            opacity: 0;
        }

        &.visible {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .hoverMenu {
        display: flex;
        flex-direction: column;
        gap: 4px;

        span {
            margin-left: 12px;
        }
    }

    .my-header {
        &-enter {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            opacity: 0;
            height: 0;
            background-color: var(--background-color-light);
        }

        &-enter-active,
        &-enter-done {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            opacity: 1;
            height: 100vh;
            background-color: var(--background-color-dark);
            transition: opacity 0.4s ease-out, height 0.6s ease-in-out, background-color 0.6s ease-in-out;
        }

        &-exit {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            opacity: 1;
            height: 100vh;
            background-color: var(--background-color-light);
        }

        &-exit-active,
        &-exit-done {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            opacity: 0;
            height: 10vh;
            background-color: var(--background-color-dark);
            transition: opacity 0.6s ease-out, height 0.4s ease-in-out;
        }
    }
}