@use 'StyleGuide.scss' as *;

.about-section {
    .top-section {
        background-color: var(--background-color);
        @include flex-leftStart(column);
        margin-top: 30px;
        padding: 60px 80px;
        padding-bottom: 80px;
        text-align: left;

        h1 {
            width: 65%;
            margin-bottom: 40px;
            color: var(--text-color-dark-secondary);
        }

        .descrip-section {
            display: grid;
            grid-template-columns: 1fr 0.8fr;
            column-gap: 60px;
            padding-bottom: 40px;
            padding-left: 10px;
            color: var(--text-color-dark);

            p {
                line-height: 170%;
                padding-top: 10px;
                padding-right: 2vw;
            }

            .image-container {
                position: relative;
                margin-top: 20px;
                margin-left: 10px;
                width: min(100%, 500px);

                .img-bg {
                    position: absolute;
                    top: -15px;
                    left: -15px;
                    width: 100%;
                    aspect-ratio: 1;
                    background-color: var(--primary-color);
                }

                img {
                    width: 100%;
                    aspect-ratio: 1;
                    position: relative;
                }
            }
        }

        @media (max-width: $breakpoint-md) {
            padding: 40px 50px;
            padding-bottom: 80px;

            h1 {
                width: 85%;
                margin-bottom: 20px;
            }

            .descrip-section {
                grid-template-columns: 100%;
                gap: 20px;

                .image-container {
                    width: 60%;
                    margin-top: 40px;
                }
            }
        }

        @media (max-width: $breakpoint-sm) {
            h1 {
                width: 100%;
                font-size: $xxlarge_font;
                margin-bottom: 10px;
            }

            .descrip-section {
                img {
                    width: 100%;
                }
            }
        }
    }

    // .his-section {
    //     background: var(--background-color-light);
    //     padding: 0 60px;
    //     padding-top: 50px;
    //     padding-bottom: 14px;

    //     h2 {
    //         color: var(--text-color-dark);
    //         font-weight: bold;
    //         margin-bottom: 44px;
    //     }

    //     .scale-image {
    //         transition: transform 0.3s ease;

    //         &:hover {
    //             transform: scale(1.2) translateY(-6px);
    //         }
    //     }

    //     @media (max-width: $breakpoint-md) {
    //         .timeline {
    //             transform: translateX(-60px);
    //         }
    //     }

    //     @media (max-width: $breakpoint-sm) {
    //         .timeline {
    //             transform: translateX(-140px);
    //             width: calc(100% + 140px);
    //         }
    //     }
    // }

    .addition-section {
        background-color: var(--background-color);
        padding: 60px 80px;
        padding-top: 10px;

        h2 {
            margin-bottom: 30px;
            font-weight: bold;
            text-align: left;
            color: var(--text-color-dark-secondary);
        }

        .content {
            .lists {
                display: flex;
                flex-wrap: wrap;
                text-align: left;
                color: var(--text-color-dark);

                & > div:not(:last-child) {
                    margin-right: 40px;
                    margin-bottom: 30px;
                }

                h4 {
                    margin-bottom: $space-md;
                }

                ul {
                    margin-left: $space-lg;
                    font-size: $mid_font;
                }
            }
        }

        @media (max-width: $breakpoint-md) {
            .list {
                margin: 30px;
                margin-top: 0px;
            }
        }

        @media (max-width: $breakpoint-sm) {}
    }

    .learnmore {
        height: 360px;
        padding-top: 46px;
        background-color: var(--background-color-dark);
        color: var(--text-color-light);

        @media (max-width: $breakpoint-md) {
            height: 400px;
            padding-top: 60px;
        }

        .slogan {
            margin: auto;
            padding-top: 10px;
            width: 80%;

            h2 {
                letter-spacing: -1px;
            }

            p {
                font-size: $large_font;
            }
        }

        .contact {
            margin-top: 50px;
            cursor: pointer;

            h4 {
                margin-bottom: 10px;
            }

            p {
                @include hover-line($start-color: var(--text-color-light), $end-color: var(--text-color-light));
            }
        }
    }
}
