@use 'StyleGuide.scss'as *;

.mainSection {
    padding: 24px;
    padding-top: 30px;
    width: 100%;


    section {
        @include flex-centerAligned;
        margin: auto;
        width: 100%;
        height: fit-content;
        position: relative;
    }

    .sections {
        @include flex-centerAligned;
    }

    .hero {
        position: relative;
        @include flex-centerAligned;
        padding-bottom: 100px;
        padding-top: 14vh;
        height: fit-content;
        color: var(--text-color);
        background-color: var(--background-color);

        .show {
            margin-top: 160px;
            width: 100%;
            height: 100%;
            // background-color: var(--background-color-dark);
            color: var(--text-color-light);
            
            &__top {
                font-family: $minor-font;
                font-size: 2.7rem;
                font-weight: bold;
                margin-bottom: 20px;
                padding-top: 60px;
            }

            &__timeline {
                .timeline {
                    width: 100%;
                    margin: auto;
                    max-width: 760px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    padding-bottom: 100px;
                    padding-top: 40px;
                    position: relative;

                    &:before {
                        position: absolute;
                        left: 50%;
                        height: 100%;
                        width: 2px;
                        background-color: var(--secondary-color);
                        transition: .6s 1.5s;
                        content: "";
                    }

                    &-headline {
                        color: var(--text-color-dark);;
                        position: absolute;
                        display: flex;
                        letter-spacing: .4px;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                        left: 0;
                        width: 100%;
                        top: 100%;
                        font-size: calc(.2vw + 10px);
                        padding: 20px 0 0 0;
                    }

                    &-title {
                        margin: 0;
                        font-weight: normal;
                        font-size: 1.6em;
                        white-space: nowrap;
                        margin-bottom: 16px;
                    }

                    &-subtitle {
                        margin: 0 0 6px 0;
                        font-family: $minor-font;
                        font-weight: normal;
                        font-size: 2em;
                        letter-spacing: 2px;
                        color: var(--text-color-dark);
                    }

                    &-excerpt {
                        font-size: $mid_font;
                        padding: 0 10px;
                        line-height: 1.4;
                        font-weight: normal;
                        margin-top: 8px;
                    }

                    &-item {
                        height: 30vh;
                        width: 45%;
                        position: relative;
                        margin: 40px 0;

                        &:nth-child(even) {
                            align-self: flex-end;
                        }
                    }

                    &-photo {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        border-radius: 5px;
                        position: relative;
                        box-shadow: 0 10px 30px rgba(0, 0, 0, .2);
                    }

                    &-photo>img {
                        overflow: hidden;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            &__next {
                width: 100%;
                padding-top: 200px;
            }
        }

        .top {
            margin-bottom: 24px;
            font-size: 1.4rem;
            letter-spacing: 1px;
            font-family: $main-font;
            // font-weight: 600;
        }

        .mid {
            margin-bottom: 34px;
            font-size: $xxlarge_font;
            font-family: $minor-font;
            letter-spacing: -1px;
            line-height: 100%;
            height: fit-content;
            overflow: hidden;
            line-height: 40px;
            transform: translateX(24px);

            @media (max-width: $breakpoint-sm) {
                transform: translateX(0);
            }

            &__container {
                display: inline-block;
                font-weight: 600;
                overflow: hidden;
                height: 60px;
                width: fit-content;

                &__text {
                    font-size: 2.0rem;
                    float: left;
                    margin: 0;
                    vertical-align: text-bottom;
                    transform: translateY(14px);

                    @media (max-width: $breakpoint-sm) {
                        transform: translateY(8px);
                    }
                }

                &__list {
                    // background-color: red;
                    // line-height: 48px;
                    margin-top: -0px;
                    padding-left: 64px;
                    text-align: left;
                    list-style: none;

                    -webkit-animation-name: change;
                    -webkit-animation-duration: 10s;
                    -webkit-animation-iteration-count: infinite;
                    animation-name: change;
                    animation-duration: 10s;
                    animation-iteration-count: infinite;

                    &__item {
                        line-height: 48px;
                        margin: 0;
                        color: var(--primary-color);
                        font-size: 3.0rem;

                        span {
                            font-size: 1.7rem;
                            color: var(--text-color-dark);
                        }

                        @media (max-width: $breakpoint-sm) {
                            font-size: 2.2rem;
                        }
                    }
                }
            }
        }

        .bot {
            line-height: 150%;
            width: min(600px, 90%);
            font-weight: 400;
        }

        .arrow {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 50px;
            cursor: pointer;

            // color: var(--primary-color);
            h5 {
                text-align: center;
                font-family: $main-font;
                font-weight: bold;
                line-height: 130%;
                // color: var(--text-color-dark);
                @include hover-line(2px, $start-color: var(--primary-color), $end-color: var(--primary-color));
            }
        }

        @-webkit-keyframes change {

            0%,
            12.66%,
            100% {
                transform: translate3d(0, 0, 0);
            }

            16.66%,
            29.32% {
                transform: translate3d(0, -25%, 0);
            }

            33.32%,
            45.98% {
                transform: translate3d(0, -50%, 0);
            }

            49.98%,
            62.64% {
                transform: translate3d(0, -75%, 0);
            }

            66.64%,
            79.3% {
                transform: translate3d(0, -50%, 0);
            }

            83.3%,
            95.96% {
                transform: translate3d(0, -25%, 0);
            }
        }

        @-o-keyframes change {

            0%,
            12.66%,
            100% {
                transform: translate3d(0, 0, 0);
            }

            16.66%,
            29.32% {
                transform: translate3d(0, -25%, 0);
            }

            33.32%,
            45.98% {
                transform: translate3d(0, -50%, 0);
            }

            49.98%,
            62.64% {
                transform: translate3d(0, -75%, 0);
            }

            66.64%,
            79.3% {
                transform: translate3d(0, -50%, 0);
            }

            83.3%,
            95.96% {
                transform: translate3d(0, -25%, 0);
            }
        }

        @-moz-keyframes change {

            0%,
            12.66%,
            100% {
                transform: translate3d(0, 0, 0);
            }

            16.66%,
            29.32% {
                transform: translate3d(0, -25%, 0);
            }

            33.32%,
            45.98% {
                transform: translate3d(0, -50%, 0);
            }

            49.98%,
            62.64% {
                transform: translate3d(0, -75%, 0);
            }

            66.64%,
            79.3% {
                transform: translate3d(0, -50%, 0);
            }

            83.3%,
            95.96% {
                transform: translate3d(0, -25%, 0);
            }
        }

        @keyframes change {

            0%,
            12.66%,
            100% {
                transform: translate3d(0, 0, 0);
            }

            16.66%,
            29.32% {
                transform: translate3d(0, -25%, 0);
            }

            33.32%,
            45.98% {
                transform: translate3d(0, -50%, 0);
            }

            49.98%,
            62.64% {
                transform: translate3d(0, -75%, 0);
            }

            66.64%,
            79.3% {
                transform: translate3d(0, -50%, 0);
            }

            83.3%,
            95.96% {
                transform: translate3d(0, -25%, 0);
            }
        }
    }

    .other {
        position: relative;
        // width: 90vw;
        width: 100%;
        height: 100vh;
        color: var(--text-color-light);
        margin: auto;

        // background-image: linear-gradient(to right, var(--primary-color), var(--background-color-dark));
        @media (max-width: $breakpoint-sm) {
            width: 100vw;
            margin-left: 40px;
            margin-right: 40px;
        }

        .top {
            z-index: 10;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100px;
            background-color: var(--background-color);

            // color: var(--text-color-dark);
            @media (max-width: $breakpoint-sm) {
                // height: 200px;
                display: none;
            }
        }

        .bot {
            z-index: 10;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 60px;
            background-color: var(--background-color);

            @media (max-width: $breakpoint-sm) {
                display: none;
            }
        }

        .showcase {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding-top: 60px;

            @media (max-width: $breakpoint-sm) {
                flex-direction: column;
            }

            .text {
                z-index: 20;
                display: flex;
                align-items: center;
                flex-direction: column;
                align-items: flex-start;
                height: 100vh;
                width: max(40%, 500px);
                // padding: 20px;
                gap: 20px;
                background-color: var(--background-color-light);

                @media (max-width: $breakpoint-sm) {
                    width: 90%;
                    height: fit-content;
                }

                .title {
                    z-index: 2;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding-right: 60px;
                    padding-top: min(14vh, 90px);
                    margin-bottom: 30px;
                    width: 100%;
                    color: var(--text-color-dark);

                    @media (max-width: $breakpoint-sm) {
                        margin-bottom: 20px;
                        padding-top: 30px;
                        padding-right: 0px;
                    }

                    .controls {
                        font-size: $large_font;
                        display: flex;
                        // flex-wrap: wrap;
                        cursor: pointer;
                        // margin-bottom: 0px;

                        a.disabled {
                            opacity: 0.5;
                            pointer-events: none;
                        }

                        a {
                            display: block;
                            width: 36px;
                            height: 36px;
                            line-height: 36px;
                            text-align: center;
                            background: rgba(0, 0, 0, 0.0);
                            color: var(--primary-color);
                            font-size: $large_font;
                            font-weight: bold;
                            transform: translateX(-10px);
                            // background-color: red;

                            &:first-child {
                                margin-right: -8px;
                            }
                        }
                    }

                    span.note {
                        font-size: $large_font;
                        font-weight: bold;
                        margin-bottom: 40px;

                        @media (max-width: $breakpoint-sm) {
                            margin-bottom: 0px;
                        }
                    }

                    h2 {
                        margin-bottom: 28px;
                        text-align: left;
                        font-family: $minor-font;
                    }

                    p {
                        width: 100%;
                        text-align: start;
                        font-size: $large_font;
                        line-height: 1.6;
                    }
                }

                a {

                    // position: relative;
                    .explore {
                        position: relative;
                        display: flex;
                        align-content: center;
                        transform: translateX(14px);
                        gap: 2px;

                        span {
                            position: relative;
                            z-index: 1;
                            color: var(--primary-color);
                            transition: color 0.5s ease-in-out;
                            font-size: $large_font;
                            font-weight: bold;
                            letter-spacing: 1px;
                        }

                        .deco {
                            position: absolute;
                            left: -12px;
                            top: 2px;
                            width: 4px;
                            height: 20px;
                            border-radius: 6px;
                            // transform: translateY(5px);
                            margin-right: 5px;
                            background: var(--primary-color);
                            transition: all 0.4s ease-in-out;
                        }

                        &:hover {
                            span {
                                color: var(--text-color-dark);
                            }

                            .deco {
                                width: 116px;
                                height: 38px;
                                border-radius: 24px;
                                transform: translateY(-10px) translateX(-3px);
                                z-index: 0;
                            }
                        }
                    }
                }

                .notready {
                    position: relative;
                    cursor: not-allowed;

                    &:hover {
                        span {
                            visibility: hidden;
                        }

                        &::after {
                            width: 150px;
                            content: 'coming soon';
                            position: absolute;
                            top: 0;
                            left: 0;
                            color: $text-color-dark;
                            font-size: 14px;
                            font-weight: bold;
                            // letter-spacing: 1px;
                            transform: translateX(-28px);
                        }

                        .deco {
                            width: 120px;
                        }
                    }
                }
            }

            .image {
                // position: absolute;
                // right: 0;
                width: min(60vw, 500px);
                height: 100%;

                @media (max-width: $breakpoint-sm) {
                    position: absolute;
                    // right: 0;
                    bottom: 0;
                    margin-top: 20px;
                    width: 100%;
                    height: 200px;
                    // height: fit-content;
                }
            }
        }
    }

    .learnmore {
        height: 420px;
        padding-top: 80px;
        background: $background-color-dark;
        color: $text-color-dark;

        @media (max-width: $breakpoint-md) {
            height: 400px;
            padding-top: 60px;
        }

        .slogan {
            margin: auto;
            padding-top: 10px;
            width: 80%;

            h2 {
                letter-spacing: -1px;
                // font-weight: ;
            }

            p {
                font-size: $large_font;
            }
        }

        .contact {
            margin-top: 50px;
            cursor: pointer;

            h4 {
                margin-bottom: 10px;
            }

            p {
                @include hover-line(2px, $text-color-dark, $text-color-dark);
            }
        }
    }
}