@use 'StyleGuide.scss' as *;

.footer {
    background-color: var(--background-color-light);
    color: var(--text-color-dark);
    padding: $space-lg;
    padding-top: 0;

    &-container {
        max-width: 1200px;
        margin: 0 auto;
        padding: $space-md;
        padding-top: 40px;
        border-top: 1px solid rgba(var(--text-color-dark), 0.1);
    }

    &-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .social-links {
            display: flex;
            gap: $space-md;
        }

        .social-link {
            color: var(--primary-color);
            display: inline-block;
            transition: color 0.2s, transform 0.2s;

            &:hover {
                color: var(--secondary-color);
                transform: translateY(-6px);
                transition: color 0.4s, transform 0.4s;
            }
        }

        .footer-copy {
            font-size: $mid_font;
        }
    }
}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .footer {
        padding: $space-md $space-md;
    }
}
