@use '../../styles/StyleGuide.scss'as *;

.circle {
    --circle-size: 30px;
    position: fixed;
    height: var(--circle-size);
    width: var(--circle-size);
    border: 2.5px solid rgba($color: $secondary-color, $alpha: 0.4);
    border-radius: 30%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 100000;
    background-color: rgba($color: $secondary-color, $alpha: 0.2);

    // background-color: var(--secondary-color);
}