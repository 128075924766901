@use 'StyleGuide.scss' as *;

.productpage {
    padding-top: $space-lg;
    color: var(--text-color);
}

.sectionTitle {
    position: fixed;
    left: 8%;
    top: 80px;

    @media (max-width: $breakpoint-md) {
        top: 50px;
    }

    h3 {
        text-align: left;
        font-size: $xxlarge_font;

        @media (max-width: $breakpoint-sm) {
            font-size: $xlarge_font;
        }
    }
}

/* ----- Hero Section ----- */
@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%) scale(0.4);
    }

    to {
        transform: translateX(0) scale(1);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.heroSection {
    position: relative;
    padding-top: 20px;
    background-color: var(--background-color);

    &Animated {
        @include flex-middle(column);
        animation: slideInFromLeft 1.4s ease-in-out;

        .titles {
            @include flex-middle(column);
        }

        .descrip {
            display: flex;
            gap: 80px;
            margin-bottom: 30px;

            h4 {
                margin-bottom: 0px;
            }

            span {
                margin-top: 200px;
            }
        }
    }

    h1,
    p {
        opacity: 0;
        line-height: 1.4;
    }

    h1 {
        animation: fadeIn 1s ease-in-out 1s forwards;
    }

    .titles p {
        font-size: $mid_font;
        width: 70%;
        margin-top: 0px;
        margin-bottom: 44px;
        animation: fadeIn 1s ease-in-out 1.4s forwards;
    }

    .logoImg {
        height: 54px;
        border: 1px solid #ccc;
        box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 5px;
    }

    .supportImg {
        margin-top: $space-md;
        height: 400px;
    }

    @media (max-width: $breakpoint-md) {
        h1 {
            font-size: $xlarge_font;
        }

        p {
            font-size: $mid_font;
            padding: 0 30px;
        }

        .supportImg {
            height: 300px;
        }
    }

    @media (max-width: $breakpoint-sm) {
        .titles {
            width: 76%;
        }
    }
}

/* ----- Progress Section ----- */
.progressSection {
    position: relative;
    background-color: var(--background-color);

    .leftLogoSection {
        position: fixed;
        height: 360px;
        width: 360px;
    }

    .deco-logo,
    .deco-logo-highlight {
        position: absolute;
        @include full-precentBox;
        transform: translateX(-50%);
        z-index: 1;
    }

    .progressPart {
        @include flex-leftStart;
        width: 50%;
        left: 38%;
    }

    .textBlock {
        @include flex-leftStart;
        text-align: left;
        transform: translateY(-35%);

        h3 {
            padding-bottom: 20px;
            font-size: 22px;
        }

        p {
            line-height: 160%;
        }
    }

    @media (max-width: $breakpoint-md) {
        .leftLogoSection {
            height: 300px;
            width: 300px;
        }

        .textBlock {
            transform: translateY(-40%);

            .title {
                font-size: $large_font;
            }

            .body {
                font-size: $mid_font;
            }
        }
    }

    @media (max-width: $breakpoint-sm) {
        .leftLogoSection {
            height: 240px;
            width: 240px;
        }

        .progressPart {
            width: 50%;
        }

        .textBlock {
            transform: translateY(-46%);
        }
    }
}

/* ----- Step Section ----- */
.stepSection {
    background-color: var(--background-color-dark);
    color: var(--text-color-light);
    display: flex;
    justify-content: center;
    width: 100%;

    .placeholder {
        background-color: var(--background-color);
        height: 100px;
    }

    .contentSection {
        pointer-events: none;

        @media (max-width: $breakpoint-md) {
            transform: translateY(-30px);
        }
    }

    .bottomFixedSection {
        height: 80px;
    }

    .progressPart {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;
        width: 750px;
        height: 300px;
        margin: auto;

        .textPart {
            width: 400px;

            h3 {
                font-size: 22px;
                font-weight: bold;
                padding-bottom: 16px;
            }

            p {
                line-height: 160%;
            }

            @media (max-width: $breakpoint-md) {
                width: 70%;
            }

            @media (max-width: $breakpoint-sm) {
                width: 85%;
            }
        }

        .gifs {
            @include flex-middle;
            width: max(320px, 60%);
            height: 100%;
            gap: 10px;

            img {
                border-radius: 4px;
                border-bottom: 3px solid #748D92;
            }

            @media (max-width: $breakpoint-md) {
                width: 80%;
            }
        }

        @media (max-width: $breakpoint-md) {
            width: 80%;
        }
    }

    @media (max-width: $breakpoint-md) {
        .progressPart {
            gap: 20px;
            flex-flow: column;
        }

        .textPart {
            width: 80%;
        }
    }
}

/* ----- Signup Section ----- */
.signUpSection {
    @include flex-centerAligned;
    padding: 24px;
    width: 100%;
    background-color: var(--background-color-dark);
    color: var(--text-color);
    text-align: left;

    .section-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 10px 50px;
        background: var(--background-color-light);

        p {
            line-height: 1.6;
            margin-bottom: 40px;
            color: var(--text-color);
            font-size: calc(#{$mid_font} + 2px);
        }

        .title-container {
            width: 60%;
            margin: auto;
            margin-bottom: 30px;

            h2 {
                font-weight: bold;
                padding-top: 50px;
                margin-bottom: 20px;
            }
        }

        .section-intro {
            padding-top: 20px;
            padding-bottom: 40px;
            width: 100%;
            background: var(--background-color);
            @include flex-centerAligned;

            .midf {
                padding: 0 110px;

                @media (max-width: $breakpoint-md) {
                    padding: 0 60px;
                }
            }

            img {
                width: min(90%, 900px);
            }
        }

        &-mid {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 40px 50px;
            background: var(--background-color);

            .title-container {
                margin-bottom: 20px;

                @media (max-width: $breakpoint-md) {
                    text-align: center;
                }
            }

            .buttonSection {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 16px;
                width: 220px;
                height: 36px;
                margin: 0 auto;
                margin-top: 10px;
                margin-bottom: 100px;

                .arrowImg {
                    width: 50px;
                    transform: translateY(-6px);
                }
            }
        }
    }

    .guide-container {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        background: var(--background-color);

        .guide-content {
            margin-bottom: 30px;

            img {
                width: min(90%, 900px);
                border-radius: 6px;
            }

            .guide-top {
                @include flex-centerAligned;
                width: 60%;
                margin: auto;

                img {
                    width: 90px;
                    height: 90px;
                    margin-bottom: 30px;
                    margin-top: 40px;
                }

                h4 {
                    margin-bottom: 20px;
                }

                p {
                    line-height: 1.6;
                    font-size: calc(#{$mid_font} + 2px);
                    width: 90%;
                    margin-bottom: 40px;
                    text-align: center;

                    @media (max-width: $breakpoint-md) {
                        font-size: $large_font;
                    }
                }
            }

            .section-intro {
                @include flex-centerAligned;
            }
        }
    }
}
