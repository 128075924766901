/* ----- 设计规范 Style Guide ----- */
// Theme
$ifDarkMode: false;

// Colors
$primary-color-light: #748D92;
$primary-color-dark: #FBA92C;
$secondary-color-light: #FBA92C;
$secondary-color-dark: #748D92;
$text-color-light: #fff;
$text-color-dark: #282f40;
$text-color-dark-secondary: #323b51;
$background-color-light: #fff;
$background-color-dark: #242b3b;

// Determining the active colors based on theme
$primary-color: if($ifDarkMode, $primary-color-dark, $primary-color-light);
$secondary-color: if($ifDarkMode, $secondary-color-dark, $secondary-color-light);
$text-color: if($ifDarkMode, $text-color-light, $text-color-dark);
$background-color: if($ifDarkMode, $background-color-dark, $background-color-light);

// Font Sizes using rem for better scalability
$xsmall_font: 0.625rem; // 10px equivalent
$small_font: 0.75rem; // 12px equivalent
$mid_font: 0.875rem; // 14px equivalent
$large_font: 1.125rem; // 18px equivalent
$xlarge_font: 1.875rem; // 30px equivalent
$xxlarge_font: 2.5rem; // 40px equivalent
$xxxlarge_font: 2.875rem; // 46px equivalent

// Font Family
$main-font: 'Tahoma', sans-serif;
$secondary-font: 'Trebuchet MS', sans-serif;
$minor-font: 'Lato', sans-serif;

// Spacing
$space-xs: 0.25rem; // 4px equivalent
$space-sm: 0.5rem; // 8px equivalent
$space-md: 1rem; // 16px equivalent
$space-lg: 1.5rem; // 24px equivalent
$space-xl: 2rem; // 32px equivalent
$space-xxl: 2.5rem; // 40px equivalent
$space-header: 5rem; // 80px equivalent

// Breakpoints for Responsive Design
$breakpoint-min: 504px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Borders
$border-radius: 36px;

// Mixins
@mixin flex-centerAligned {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin flex-middle($direction: null) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;

    @if $direction !=null {
        flex-direction: $direction;
    }
}

@mixin flex-leftStart($direction: null) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @if $direction !=null {
        flex-direction: $direction;
    }
}

@mixin full-precentBox {
    width: 100%;
    height: 100%;
}

@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// Breakpoints for Responsive Design
@mixin respond-to($breakpoint) {
    @if $breakpoint=="small" {
        @media (max-width: $breakpoint-sm) {
            @content;
        }
    }

    @else if $breakpoint=="medium" {
        @media (max-width: $breakpoint-md) {
            @content;
        }
    }

    @else if $breakpoint=="large" {
        @media (max-width: $breakpoint-lg) {
            @content;
        }
    }

    @else if $breakpoint=="xlarge" {
        @media (max-width: $breakpoint-xl) {
            @content;
        }
    }
}

// Hover effect with vendor prefixes
@mixin hover-line($height: 2px, $start-color: $text-color-light, $end-color: $text-color-light) {
    display: inline;
    background: linear-gradient(to right, $start-color, $end-color) no-repeat right bottom;
    background-size: 0 $height;
    transition: background-size 0.8s;

    &:hover {
        background-position: left bottom;
        background-size: 100% $height;
    }
}

// Layered box shadow
@mixin layered-box($alpha: 0.055, $shadow-color: #282f40) {
    // Use a default value or pass a color directly, avoiding Sass functions
    $shadow-color: if(type-of($shadow-color)=='color', $shadow-color, #282f40);

    box-shadow:
        0 1px 1px rgba($shadow-color, $alpha),
        0 2px 2px rgba($shadow-color, $alpha),
        0 4px 4px rgba($shadow-color, $alpha),
        0 8px 8px rgba($shadow-color, $alpha),
        0 16px 16px rgba($shadow-color, $alpha);
}

// Border settings with vendor prefixes
@mixin border-setting($width: 1px, $style: solid, $color: #000) {
    border-width: $width;
    border-style: $style;
    border-color: $color;
}

@mixin button($bcolor, $url, $x1, $y1, $bor, $col) {
    background: $bcolor;
    // -webkit-mask: url('/assets/portfolio/urban-sprite.png');
    // -webkit-mask: url('https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png');
    // -webkit-mask: url($url);
    // mask: url('/assets/portfolio/urban-sprite.png');
    -webkit-mask-size: $x1 $y1;
    mask-size: $x1 $y1;
    border: $bor;
    color: $col;
}
