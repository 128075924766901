@use '../../styles/StyleGuide.scss'as *;

.myComponent {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    margin: 0;
    background: var(--background-color);
    color: var(--text-color-dark);
    .tite {
        font-size: 6rem;
        letter-spacing: -7px;
        font-family: $minor-font;
        animation: glitch 1s linear infinite;

        after {
            animation: glitchBotom 1.5s linear infinite;
            clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
            -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
        }
    }
    .textSection{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .text{
        font-weight: bold;
        font-size: $large_font;
    }
}

@keyframes glitch {

    2%,
    64% {
        transform: translate(2px, 0) skew(0deg);
    }

    4%,
    60% {
        transform: translate(-2px, 0) skew(0deg);
    }

    62% {
        transform: translate(0, 0) skew(5deg);
    }
}

.myComponent.tite:before,
.myComponent.tite:after {
    content: attr(title);
    position: absolute;
    left: 0;
}

.myComponent.tite:before {
    animation: glitchTop 1s linear infinite;
    clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop {

    2%,
    64% {
        transform: translate(2px, -2px);
    }

    4%,
    60% {
        transform: translate(-2px, 2px);
    }

    62% {
        transform: translate(13px, -1px) skew(-13deg);
    }
}

.myComponent.tite:after {
    animation: glitchBotom 1.5s linear infinite;
    clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom {

    2%,
    64% {
        transform: translate(-2px, 0);
    }

    4%,
    60% {
        transform: translate(-2px, 0);
    }

    62% {
        transform: translate(-22px, 5px) skew(21deg);
    }
}