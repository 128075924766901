@use '../../styles/StyleGuide.scss' as *;

.StepProgressBar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    cursor: pointer;

    .steps {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .node {
            @include flex-middle(column);
            row-gap: 10px;

            .text {
                font-weight: bold;
                opacity: 0;
                transform: translate(2px, 20px);
                transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
                transition-delay: 0.1s;

                &.selected {
                    opacity: 1;
                    transform: translate(2px, 0);
                }
            }

            .step {
                width: 26px;
                height: 26px;
                background: var(--primary-color);
                border: 2px solid var(--text-color-light);
                border-radius: 50%;
                transition: 1s;

                &.selected {
                    border: 2px solid var(--secondary-color);
                    background: var(--background-color-light);
                }
            }
        }
    }

    .progress {
        position: absolute;
        width: 90%;
        height: 50%;
        transform: translateY(50%);
        border-bottom: 2px solid var(--background-color-light);
        z-index: -1;

        .percent {
            position: absolute;
            width: 0;
            height: 100%;
            border-bottom: 5px solid var(--background-color-light);
            transform: translateY(3px);
            z-index: 1;
            transition: width 1s;
        }
    }
}
