@use 'StyleGuide.scss' as *;

.my-button {
    font-family: $main-font;
}

/* ----- Button_MenuList ----- */
.buttonMenuList {
    width: 100%;
    font-size: $mid_font;
    padding: $space-sm 0;
    color: var(--text-color-dark);
    text-align: left;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
    }

    &:active {
        opacity: 0.5;
    }
}

/* ----- Button_Menu ----- */
.buttonMenu {
    font-size: $small_font;
    display: block;
    padding: $space-xs 20px;
    color: var(--text-color-light);
    text-align: center;
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: $border-radius;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &:hover {
        color: var(--text-color-dark);
        background-color: #ffffff;
    }

    &:active {
        opacity: 0.5;
    }
}

/* ----- Button_Common ----- */
.buttonMain {
    letter-spacing: 1px;
    font-size: $mid_font;
    display: block;
    padding: $space-sm $space-lg;
    color: var(--text-color-light);
    text-align: center;
    background-color: var(--primary-color);
    border: 2px solid var(--background-color-dark);
    border-radius: $border-radius;
    transition: background-color 0.3s ease;
    // cursor: pointer;
    @include layered-box($alpha: 0.1, $shadow-color: var(--background-color-light));

    &:hover,
    &:active {
        // Only apply hover and active styles if not disabled
        @at-root #{selector-unify(&, ':not(.disable)')} {
            &:hover {
                color: var(--text-color-dark);
                border-color: var(--text-color-dark);
                background-color: var(--secondary-color);
            }

            &:active {
                opacity: 0.5;
            }
        }
    }

    &.disable {
        background-color: rgb(49, 47, 80);
        border: 2px solid rgb(93, 89, 141);
        text-decoration: line-through;
        cursor: not-allowed;
    }
}

/* ----- Button_Submit ----- */
.buttonSubmit {
    position: relative;
    @include flex-middle;
    width: 260px;
    height: 50px;
    border: 2px solid var(--background-color-dark);
    border-radius: 30px;
    text-align: center;
    font-size: calc(#{$mid_font} + 2px);
    color: var(--text-color-light);
    background-color: var(--background-color-dark);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    .icon-check {
        position: absolute;
        color: var(--text-color-dark);
        opacity: 0;
        font-size: 26px;
        top: 20%;
        left: 25%;
    }

    .circle {
        position: absolute;
        width: 62px;
        height: 62px;
        fill: none;
        stroke: var(--text-color-dark);
        stroke-width: 3px;
        stroke-dasharray: 183 183;
        opacity: 0;
        left: 50%;
        top: 50%;
        pointer-events: none;
        transform: translate(-50%, -50%) rotate(-90deg);
    }

    &:hover {
        color: var(--text-color-dark);
        background-color: #ffffff;
    }

    &.checked {
        animation: buttonSubmit 0.5s ease both, buttonSubmit_fill 0.5s ease-out 1.5s forwards;

        .icon-check {
            animation: buttonSubmit_check 0.5s ease-out 1.5s both;
        }

        .circle {
            animation: buttonSubmit_circle 2s ease-out 0.5s both;
        }
    }
}

@keyframes buttonSubmit {
    0% {
        width: 260px;
        border-color: var(--text-color-dark);
        color: var(--text-color-light);
    }

    50% {
        color: transparent;
    }

    100% {
        width: 50px;
        border-color: var(--text-color-dark);
        background: transparent;
        color: transparent;
    }
}

@keyframes buttonSubmit_fill {
    0% {
        background: transparent;
        border-color: var(--text-color-dark);
    }

    100% {
        background: rgba(var(--text-color-dark), 0.1);
    }
}

@keyframes buttonSubmit_check {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes buttonSubmit_circle {
    0% {
        stroke-dashoffset: 183;
    }

    50% {
        stroke-dashoffset: 0;
        stroke-dasharray: 183;
        transform: translate(-50%, -50%) rotate(-90deg) scale(1);
        opacity: 1;
    }

    100% {
        stroke-dasharray: 500 500;
        transform: translate(-50%, -50%) rotate(-90deg) scale(1.3);
        opacity: 0;
    }
}

/* ----- Button_Close ----- */
.buttonClose {
    color: #d6d6d6;
    display: block;
    padding: calc(#{$space-xs} + 1px);
    text-align: center;
    background-color: #ffffff;
    border: 2px solid #d6d6d6;
    border-radius: 2px;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &:hover {
        background-color: rgba(214, 214, 214, 0.3);
    }

    &:active {
        opacity: 0.5;
    }
}

/* ----- Button_Window ----- */
.buttonWindow {
    font-size: $small_font;
    font-weight: bold;
    padding: $space-sm 30px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    &.main {
        background-color: var(--primary-color);
        color: var(--text-color-light);
        border: 2px solid var(--primary-color);

        &:hover {
            background-color: #ffffff;
            color: var(--primary-color);
        }

        &:active {
            opacity: 0.5;
        }
    }

    &.secondary {
        background-color: #ffffff;
        color: var(--text-color-dark);
        border: 2px solid #d6d6d6;

        &:hover {
            background-color: rgba(var(--background-color-dark), 0.05);
            color: rgba(var(--background-color-dark), 0.5);
        }

        &:active {
            opacity: 0.5;
        }
    }
}

/* ----- More button ----- */
.oink-more-container {
    position: relative;
    width: 100px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    // border: 1px solid $background-color-dark;
    font-family: $minor-font;
    font-weight: 300;
    transition: 0.5s;
    letter-spacing: 1px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    @include layered-box;
    cursor: pointer;

    .hover-content{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: var(--background-color-dark);
        color: var(--text-color-light);

        .mas {
            width: 50%;
            font-weight: 300;
            font-size: $mid_font;
            text-align: center;
            font-family: $minor-font;
            overflow: hidden;
            font-weight: bold;
            letter-spacing: 1px;
            margin-right: 4px;
        }
    }

    .button {
        position: absolute;
        @include button(var(--primary-color), "", 3000%, 100%, none, var(--text-color-light));
        -webkit-animation: ani2 0.7s steps(29) forwards;
        animation: ani2 0.7s steps(29) forwards;
        &:hover {
            -webkit-animation: ani 0.7s steps(29) forwards;
            animation: ani 0.7s steps(29) forwards;
        }
        .mas{
            font-weight: 200;
        }
    }
}

@-webkit-keyframes ani {
    from {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
    }

    to {
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
    }
}

@keyframes ani {
    from {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
    }

    to {
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
    }
}

@-webkit-keyframes ani2 {
    from {
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
    }

    to {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
    }
}

@keyframes ani2 {
    from {
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
    }

    to {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
    }
}

/* ----- Play button ----- */
.oink-play-container {
    position: relative;
    width: 42px;
    height: 42px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    font-weight: 300;
    transition: 0.5s;
    letter-spacing: 1px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    @include layered-box;
    // cursor: pointer;

    .hover-content{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: var(--secondary-color);
        color: var(--text-color-light);

        .mas {
            font-weight: 300;
            font-size: $mid_font;
            text-align: center;
            font-family: $minor-font;
            overflow: hidden;
            font-weight: bold;
            letter-spacing: 1px;
            margin-right: 4px;
        }
    }

    .button {
        position: absolute;
        @include button(var(--background-color-dark), "", 3000%, 100%, none, var(--text-color-light));
        -webkit-animation: ani2 0.7s steps(29) forwards;
        animation: ani2 0.7s steps(29) forwards;
        &:hover {
            -webkit-animation: ani 0.7s steps(29) forwards;
            animation: ani 0.7s steps(29) forwards;
        }
        .mas{
            font-weight: 200;
        }
    }
}