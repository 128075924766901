@import './styles/StyleGuide.scss';

// Using CSS Variables for Dynamic Theming
:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --text-color: #{$text-color};
  --background-color: #{$background-color};
  --background-color-light: #{$background-color-light};
  --background-color-dark: #{$background-color-dark};
  --text-color-light: #{$text-color-light};
  --text-color-dark: #{$text-color-dark};
}

html {
  scroll-behavior: smooth;
  cursor: none !important;
  @include flex-centerAligned;
}

body {
  min-width: $breakpoint-min;
  max-width: 1920px;
}

.App {
  color: var(--text-color-dark);
  text-align: center;
}

iframe {
  pointer-events: none;
}

a {
  text-decoration: none;
  font-family: $main-font;
  cursor: none !important;
}

li {
  font-family: $secondary-font;
  margin-bottom: 5px;
}

p,
span {
  font-family: $secondary-font;
}

p {
  font-size: $large_font;
}

span {
  font-size: $mid_font;
}

li {
  font-size: $large_font;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $main-font;
}

h1 {
  margin: 20px 0;
  font-size: $xxxlarge_font;
  font-weight: bold;
}

h2 {
  margin: 14px 0;
  font-size: $xxlarge_font;
  font-weight: normal;
}

h3 {
  margin: 10px 0;
  font-size: $xlarge_font;
  font-weight: normal;
}

h4 {
  margin: 10px 0;
  font-size: $large_font;
}

h5 {
  font-size: $small_font;
  letter-spacing: 1px;
  // font-family: $secondary-font;
}

.no-style-link {
  text-decoration: none;
  cursor: none !important;
  color: inherit;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: inherit;
  }
}

.main-content {
  @include flex-centerAligned;
  max-width: 1920px;
}

.Title__Section {
  font-family: $minor-font;
  font-size: 2.7rem;
  font-weight: bold;
  margin: auto;
  color: var(--text-color-dark);
}